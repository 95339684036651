/** @format */

import { bindable } from 'aurelia-framework';
import driversService from 'services/api/driversService';

export class DriverSearchList {
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;

  constructor() {
    this.driversService = driversService;

    this.loading = false;
    this.items = [];
    this.selectedItemIds = null;

    this.qLoad = this.loadData();
  }

  loadData() {
    this.loading = true;
    return this.driversService.get().then(items => {
      this.items = items;
      this.loading = false;
    });
  }

  selectedIdsChanged(ids) {
    if (!this.qLoad) return;

    this.qLoad.then(_ => {
      this.selectedItemIds = ids;
    });
  }
}
