/** @format */

import { customElement, bindable } from 'aurelia-framework';
import { AssetNameFormatter } from 'components/value-converters/asset-formatted-name-vc';

import assetsService, {
  AssetStates,
  AssetTypes
} from 'services/api/assetsService';

import uiService from 'services/uiService';

import eventService, { EventsList } from 'services/eventService';

import './asset-list.scss';

const ASSET_ICONS = {
  [AssetTypes.vehicle]: 'fi-car',
  [AssetTypes.mobile]: 'fi-smartphone'
};

const getIcon = asset => {
  let travel = asset.status === AssetStates.travelling;
  let panic = asset.panic;

  return panic
    ? 'fi-alert-circle'
    : travel ? 'fi-navigation_arrow' : ASSET_ICONS[asset.assetType];
};

const getCSSClass = (asset, preview) => {
  return `item-${asset.status} ${preview ? 'item-preview' : ''}`;
};
/**
 * Collapsible list component
 */
@customElement('asset-list')
export class AssetList {
  @bindable isLoading;
  @bindable closed;

  @bindable filter;
  @bindable onItemSelected;
  @bindable onGroupSelected;

  @bindable editMode;
  @bindable autoLoad;

  @bindable previewItem;

  constructor() {
    this.assetsService = assetsService;
    this.uiService = uiService;

    this.assetList = [];
    this.isLoading = false;

    this.filter = '';
    this.closed = false;
    this.editMode = false;

    this.autoLoad = false;

    this.onItemClick = this.onItemClick.bind(this);
    this.onGroupClick = this.onGroupClick.bind(this);
  }

  attached() {
    this.toggleRefresh(this.autoLoad);
    eventService.subscribe(EventsList.UserDetailsChanged, this.loadData);
    this.loadData();
  }

  detached() {
    this.toggleRefresh(false);
    eventService.unsubscribe(EventsList.UserDetailsChanged, this.loadData);
  }

  loadData = silent => {
    this.isLoading = !silent;
    this.assetsService.getAll().then(assets => {
      this.assetList = assets.map(this.assetToList);
      this.isLoading = false;
    });
  };

  assetToList(asset) {
    let showArrow = asset.status === AssetStates.travelling;
    let { id, name, shortAddress, groupName, groupId } = asset;

    //vc is used directly because vc values are not updated when individual items change in the list
    //and binding signalers run the vc for the whole list everytime
    let formattedName = AssetNameFormatter.formatName(asset) || name;
    
    return {
      asset: asset, //original class
      //
      id,
      name: name,
      formattedName,
      groupName,
      groupId,
      shortAddress,
      //
      selected: false,
      icon: getIcon(asset),
      iconRotation: showArrow ? asset.heading : 0
    };
  }

  autoLoadChanged(value) {
    this.autoLoad = value === true || value === 'true';
    this.toggleRefresh(this.autoLoad);
  }

  closedChanged(value, oldValue) {
    if (oldValue === true) {
      this.assetList.forEach(a => (a.selected = false));
    }
  }

  previewItemChanged(id) {
    (this.assetList || []).forEach(
      i => (i.cssClass = getCSSClass(i.asset, i.id === id))
    );
  }

  onItemClick(item, event) {
    if (item) {
      let action = event.target.dataset.action;
      if (typeof this.onItemSelected === 'function') {
        this.onItemSelected(item.asset, action);
      }
    }
  }

  onGroupClick(group) {
    if (this.onGroupSelected && group) {
      let { id, name } = group;
      this.onGroupSelected({
        id,
        name,
        items: group.items.map(i => {
          return i.asset;
        })
      });
    }
  }

  toggleRefresh(refresh) {
    if (refresh) {
      eventService.subscribe(
        EventsList.SignalrbroadcastSnapshot,
        this.updateItem
      );
    } else {
      eventService.unsubscribe(
        EventsList.SignalrbroadcastSnapshot,
        this.updateItem
      );
    }
  }

  updateItem = update => {
    if (!update || !this.assetList || !this.assetList.length) return;

    let item = this.assetList.find(a => a.id == update.id);
    if (item && item.asset) {
      item.asset.updateState(update);
      //update list item object
      Object.assign(item, this.assetToList(item.asset, null));
      item.cssClass = getCSSClass(item.asset, this.previewItem === item.id);
    }
  };
}
