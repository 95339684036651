/** @format */

import { bindable } from 'aurelia-framework';
import provisionService from 'services/api/provisionService';

export class AccountsSearchList {
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;

  constructor() {
    this.provisionService = provisionService;

    this.accounts = [];
    this.qLoad = null;
    this.loading = false;
  }

  bind() {
    this.qLoad = this.loadAccounts();
  }

  loadAccounts() {
    this.loading = true;
    return this.provisionService.getAccounts().then(accounts => {
      this.accounts = accounts;
      this.loading = false;
    });
  }

  selectedIdsChanged(ids) {
    if (!this.qLoad) return;

    this.qLoad.then(() => {
      this.selectedIds = ids;
    });
  }
}
