import { bindable } from 'aurelia-framework';

export class TimePeriod {

  @bindable dayStart;
  @bindable dayEnd;
  
  constructor() {
    this.dayStart = "09:00"
    this.dayEnd = "17:00" 
  }
}
