/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import assetsService from 'services/api/assetsService';
import uiService from 'services/uiService';

export class AssetSearchList {
  @bindable onListChanged;
  @bindable selectedIds;
  @bindable multiSelect;
  @bindable unassigned;
  @bindable hideSelected;
  @bindable returnAll;

  constructor() {
    this.assetsService = assetsService;

    this.loading = false;
    this.assets = [];
    this.hideSelected = false;
    this.returnAll = true;
    
    this.qLoad = this.loadAssets();
  }

  @computedFrom('uiService.showGroups')
  get showGroups() {
    return uiService.showGroups;
  }

  loadAssets() {
    this.loading = true;
    return this.assetsService.getAll(!!this.unassigned).then(assets => {
      this.assets = assets;
      if (this.hideSelected && this.selectedIds) {

        this.assets.forEach(a => {
          if(this.selectedIds.includes(a.id)) {
            a.disabled = true;
          }
        })
      }
      this.loading = false;
      return assets;
    });
  }

  selectedIdsChanged(ids) {
    //wait for assets to load
    if (!this.qLoad) return;

    this.qLoad.then(() => {
      this.selectedAssetIds = ids;
    });
  }
}
