import { bindable, inject, observable } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { DateTimeUtils as DTU } from '@fonix/web-utils';

import { Store } from 'aurelia-store';
import * as Actions from '../../../../pages/cctv/components/cctv-module-store-manager/actions.js';

import './time-picker.scss';

export class TimePicker {
  @bindable time;
  @bindable seconds;
  @bindable onTimeChanged;
  @bindable disabled;
  @bindable label;
  @bindable minIncrement;
  @bindable minTime;
  @bindable maxTime;
  @bindable name;
  @bindable enableSeconds;


  static inject = [Store];

  constructor(store) {
    this.store = store;
    this.actions = Actions;
    this.time = null;
    this.seconds = false;
    //check flatpickr js for options
    this.options = {
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      altInput: true,
      dateFormat: 'H:i',
      allowInput: false,
      enableSeconds: false,
      altFormat: 'H:i',
      minuteIncrement: 30,
      minTime: '00:00:00',
      maxTime: '23:59:59',
      onClose: this.onInputClose.bind(this),
      onOpen: this.onInputOpen.bind(this),
      onChange: this.onValueChanged.bind(this)
    }

    this.pickerObj = null;
    this.disabled = false;
    this.label = null;

  }

  attached() {
    if (this.enableSeconds) {
      this.options.dateFormat = 'H:i:S';
      this.options.altFormat = 'H:i:S';
    }
    
    this.minTime ? this.options.minTime = this.minTime : '';
    this.maxTime ? this.options.maxTime = this.maxTime : '';
    this.minIncrement ? this.options.minuteIncrement = this.minIncrement : '';
    this.build();
    this.updateTime();

    this.store.state.subscribe((state) => {
      if (state.generatedDeviceId) {
        if (this.label === 'start') {
          this.time = '00:00';
        } else {
          this.time = '23:59';
        }
        this.actions.changedDeviceId(null);
      }
    });
  }

  detached() {
    this.destroy();
  }

  maxTimeChanged(newVal) {
    if (this.pickerObj) {
      this.pickerObj.config.maxTime = newVal;
    }
  }

  minTimeChanged(newVal) {
    if (this.pickerObj) {
      this.pickerObj.config.minTime = newVal;
    }
  }

  build() {
    this.destroy();

    if (this.refInput) {
      this.pickerObj = new flatpickr(this.refInput, this.options);
    }
  }

  destroy() {
    if (this.pickerObj) {
      this.pickerObj.destroy();
    }
  }

  secondsChanged(value) {
    if (value === true || value === 'true') {
      this.options.enableSeconds = true;
      this.options.altFormat = this.options.dateFormat = 'H:i:S';
      this.build();
    }
  }

  timeChanged(time, oldtime) {
    this.updateTime(time, oldtime);
  }

  onValueChanged(obj, value) {
    this.time = value;

    if (this.onTimeChanged) {
      //defer to allowed bind to update (timeChanged must be )
      setTimeout(() => this.onTimeChanged(value, this.label), 0)
    }
  }

  onInputClose(obj, value) {
    this.time = value;
  }

  //find better way to do this
  onInputOpen(obj, value) {
    if (this.disabled) {
      this.pickerObj.close()
    }
  }

  updateTime() {
    if (this.pickerObj && this.time && this.time.length) {
      let _time = DTU.parseTime(this.time); //convert str to a datetime so the picker sets it.
      this.pickerObj.setDate(_time);
    }
  }
}

