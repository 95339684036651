/** @format */
import {
  inject,
  customElement,
  bindable,
  computedFrom
} from 'aurelia-framework';
import './textarea-input.scss';

@customElement('textarea-input')
@inject(Element)
export class TextareaInput {
  @bindable value;
  @bindable name;
  @bindable label;
  @bindable postLabel;
  @bindable placeholder;
  @bindable cssClass;
  @bindable textareaCssClass;
  @bindable readonly;
  @bindable disabled;
  @bindable required
  @bindable keepActive;
  @bindable maxlength;
  @bindable cols;
  @bindable rows;
  @bindable autofocus;
  @bindable dataError;
  @bindable textareaElement

  @bindable onValueChange;

  constructor(element) {
    this.element = element

    this.value = null;
    this.name = null;
    this.label = null;
    this.postLabel = null;
    this.dataError = '';
    this.cssClass = '';
    this.textareaCssClass = '';
    this.readonly = null;
    this.disabled = null;
    this.keepActive = false;
    this.autofocus = false;
    this.initialHeight = 0;

    this.onChange = this.onChange.bind(this);
  }

  attached() {
    if (this.autofocus) {
      this.textareaElement.setAttribute('autofocus', true);
      setTimeout(() => {
        this.textareaElement.focus()
      }, 250);
    }
    if (this.placeholder) {
      this.textareaElement.setAttribute('placeholder', this.placeholder);
    }
    if (this.required) {
      this.textareaElement.setAttribute('required', this.required);
    }
    if (this.maxlength) {
      this.textareaElement.setAttribute('maxlength', this.maxlength);
    }
    if (this.cols) {
      this.textareaElement.setAttribute('cols', this.cols);
    }
    if (this.rows) {
      this.textareaElement.setAttribute('rows', this.rows);
    }
    if (this.dataError) {
      this.textareaElement.setAttribute('title', this.dataError);
    }

    this.autosize();
  }

  bind(bindingContext, overrideContext) {
    this.autosize();
  }

  onBlur(event) {
    this.elementFocusStatus(event, false)
  }

  onFocus(event) {
    this.elementFocusStatus(event, true)
  }

  autosize() {
    if (!this.textareaElement) return;

    var el = this.textareaElement;
    if (!this.initialHeight) {
      this.initialHeight = el.clientHeight;
    }

    el.style.height = 'auto';
    let extraHeight = el.scrollHeight <= this.initialHeight ? 10 : 0;
    el.style.height = (el.scrollHeight - extraHeight) + 'px';
  }

  elementFocusStatus(event, status) {
    var eventDetails = new CustomEvent('element-status-changed', {
      detail: {
        event,
        status
      },
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }

  valueChanged(newValue) {
    this.autosize();
  }

  onChange(e) {
    var eventDetails = new CustomEvent('textarea-value-changed', {
      detail: this.value,
      bubbles: true
    });

    this.element.dispatchEvent(eventDetails);
  }
}
